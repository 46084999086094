$light-grey: #b9b9b9;

.thankyou {
  img.hero {
    height: 200px;
    @media (min-width: 375px) {
      height: 300px;
    }
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .power-by {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      height: 2em;
    }
  }

  .submitted {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.5em;
      height: auto;
    }
  }

  .btn-secondary {
    background-color: $light-grey;
    border: none;
    border-radius: 20px;
  }
}
