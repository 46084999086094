.modal {
  .modal-content {
    background-color: #e4e4e4;
  }
  .btn {
    border: none;
  }
  input[type="text"]::placeholder {
    font-size: 1em;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      display: block;
      // margin: 1em 3em 0;
      // border: 1px solid #cdcdcd;
      // padding: 2.5em 3em;
      border-radius: 6px;
      // background-color: white;
      cursor: pointer;
    }
  }
  label {
    cursor: pointer;
  }
      svg {
        height: 4em;
        width: 4em;
      }
  .error {
    text-align: center;
    font-size: 0.7em;
    color: red;
  }
}
