$grey: #c7c7c7;
$dark-grey: #717171;
$red: #cf0505;

.review {
  min-height: calc(100vh - 100px);
  position: relative;

  .item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: .5em;
    min-height: 40px;
    .rank {
      border-radius: 50%;
      border: 1px solid $grey;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.selected {
        border: none;
        background-color: $dark-grey;
        color: white;
      }
    }

    &.open {
      .rank {
        top: 2px;
      }
    }

    .info {
      margin-left: 12px;
      text-align: left;
      display: flex;
      width: calc(100% - 47px);
      input.brand {
        flex: 1;
      }

      input.model {
        flex: 3;
      }

    }
  }
  .error {
    font-weight: 700;
  }
}

.next {
  position: absolute;
  right: 0;
  bottom: 0;

  .next-btn-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .error {
      text-align: right;
      display: flex;
      align-items: center;
      color: $red;
    }

    .next-btn {
      background-color: $dark-grey;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
        height: 100%;
        fill: white;
        z-index: 10;
      }
    }
  }
}