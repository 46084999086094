.mileage-modal {
  button {
    padding: 10px 35px;
    border-radius: 30px;
  }
  input[type="number"] {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    font-size: 2em;
    text-align: center;
    width: 60%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
  .km {
    color: #717171;
    font-size: 1.25em;
  }
}
