.votes {
  svg {
    height: 1.7em;
    width: auto;
  }
  .pages {
    a {
      cursor: pointer;
      color: #aeaeae;;
      &.selected {
        color: initial;
        font-weight: 500;
      }
    }
  }

  ol, ul {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
  }
  li {
      text-align: left;
  }
}
