$light-grey: #f3f3f3;
$dark-grey: #717171;

.ranking {
  position: relative;
  overflow-y: visible;
  background-color: #f8f8f8;

  .clickable {
    cursor: pointer;
  }
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }
  .product-link {
    text-decoration: none;
    color: initial;
  }
  .img-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 200px;
    overflow: hidden;
    @media (min-width: 768px) {
      height: 250px;
    }

    &.default {
      background-color: $light-grey;
    }

    // img {
    //   object-fit: cover;
    //   width: 100%;
    //   &.default {
    //     height: 55px;
    //     width: auto;
    //   }
    // }
  }
  .model-title {
    font-size: 1.25em;
    font-weight: 700;
  }
  .rank-info {
    color: $dark-grey;
    font-size: 0.9em;
  }
  .vote-now {
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    color: initial;
  }

  &.ranking-subtitle {
    font-weight: 500;
  }

  .name-title {
    margin-left: 1em;
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  .table {
    border-collapse: separate;
    border-spacing: 15px;
    .custom-width {
      width: unset;
    }
    th, td {
      background-color: #ffffff; // rgb(245, 245, 245);
    }
    .h6 {
      font-size: 1rem;
    }

    .table-rows {
      vertical-align: middle;
    }

    @media (max-width: 576px) {
      border-spacing: 3px;
      padding: 0 11px;
      .table-title {
        font-size: .7rem;
      }
      .custom-width {
        width: 100%;
      }
      td, .h2 , .name-title {
        font-size: .8rem;
      }
      .h6 {
        font-size: .8rem;
        font-weight: 600 !important;
        width: 100%;
      }
    }
  }
}

.rank-icon {
  position: relative;
  &.rank-icon-image{
    width: 38px;
    height: auto;
    @media (max-width: 576px) {
      width: 24px;
      height: auto;
    }
  }

  &.rank-icon-text {
    font-weight: bold;
    color: #f3f3f3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -8px;
    @media (max-width: 576px) {
      margin-top: -4px;
    }
  }
}

.card-img-top {
  width: 175px;
  height: 135px;
  object-fit: cover;
  object-position: center center;

  @media (max-width: 576px) {
    width: 60px;
    height: auto;
    object-fit: cover;
    object-position: center center;
    object-fit: scale-down; 
    max-width: 60px;
  }
}

.card {
  @media (max-width: 576px) {
    p {
      font-size: 12px;
    }
    .h1 {
      font-size: 18px;
    }
    .h2 {
      font-size: 16px;
    }
    .h3 {
      font-size: 14px;
    }
    .h4 {
      font-size: 14px;
    }
    .h5 {
      font-size: 13px;
    }
    .h6 {
      font-size: 12px;
    }
  }
}

.h2 {
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.votes {
  .votes-btn {
    background-color: #e5e5e5;
  }
}

.greeting {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 1px #eaeaea;
  border: 1px solid #e4e4e4;
  position: fixed;
  bottom: 65px;
  font-size: 15px;
  text-align: right;
  opacity: 1;
  transition: opacity 3s ease;

  @media (max-width: 576px) {
    font-size: 12px;
  }

  &.hide {
    display: none;
  }

  &.show {
    opacity: 1;
    display: block;
    transition: opacity 3s ease;
  }
}

.hide-greeting-button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

.rank-button {
  color: #FFFFFF !important;
  font-weight: 700;
  background-color: #058BCA;
  padding: 10px 15px;
  border-radius: 15px;
  text-decoration: none;

  position: fixed;
  font-size: 1.1em;
  z-index: 10;
  bottom: 15px;

  @media (max-width: 576px) {
    font-size: 0.9em;
  }
}

