.header {
  a {
    text-decoration: none;
    color: initial;
  }
  // @media only screen and (max-width: 600px) {
  //   a {
  //     font-size: 0.7em;
  //   }
  // }
  // @media only screen and (max-width: 400px) {
  //   a {
  //     font-size: 0.7em;
  //   }
  // }
  .btn-logo {
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    .logo {
      height: 1.65em;
    }
  }
  .header.emos {
    display: flex;
    img {
      padding: 0 1px;
      height: 14px;
      @media (min-width: 576px) {
        height: 20px;
      }
    }
  }
}

.cls-1 {
  fill: #fe595e;
}
.cls-2 {
  stroke: #ff93a6;
}
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-8 {
  fill: #058bca;
}
.cls-9 {
  fill: #ff93a6;
}
.cls-3 {
  stroke: #fbc12f;
}
.cls-4 {
  stroke: #000;
}
.cls-10 {
  fill: #a65848;
}
.cls-5 {
  stroke: #a65848;
}
.cls-6 {
  stroke: #fe595e;
}
.cls-7 {
  stroke: #008ac9;
}
.cls-11 {
  fill: #fbc12f;
}

// .rank-button {
//   color: #FFFFFF !important;
//   font-weight: bold;
//   background-color: #058BCA;
//   padding: 10px 15px;
//   border-radius: 15px;
//   text-decoration: none;

//   position: fixed;
//   right: 1.5em;
//   font-size: 1.1em;
//   z-index: 10;
//   bottom: 50px;

//   @media (max-width: 576px) {
//     font-size: 0.9em;
//   }
// }

.logo {
  width: 180px;
  @media (max-width: 576px) {
    width: 100%;
  }
}
