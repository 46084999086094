$blue: #538AF1;
$dark-grey: #717171;
$grey: #b9c3cc;

.shoe {
  .btn-emoji {
    width: 100%;
    border-radius: 30px;
    background-color: $grey;
    color: white;
    &:focus {
      box-shadow: none;
    }
  }
  .emojis {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5em;
    justify-content: center;
  }
  // .rank-them-btn {
  //   top: 10%;
  // }
  .title {
    font-size: 1.25em;
    font-weight: 700;
  }
  ul {
    color: $dark-grey;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9em;
  }
  .submit-btn {
    border: none;
    padding: 8px 20px;
    border-radius: 30px;
    color: white;
    background-color: $grey;
    font-size: 0.9em;
    margin-left: 1em;
  }
  .attribute {
    pointer-events: none;
    padding: 8px 20px;
    border-radius: 30px;
    border: 1px solid #6c757d;
    background: transparent;
  }
  input[type="text"] {
    padding: 7px 1em;
  }
  .like-btn {
    padding: 6px;
    border: 2px solid $grey;
    border-radius: 50%;
    svg {
      width: 25px;
      fill: $grey;
    }
    &.voted {
      border-color: $blue;
      svg {
        fill: $blue;
      }
    }
  }
  .back {
    top: 1.5em;
    left: 1.5em;
    position: absolute;
  }
  .rank-container {
    font-size: 1em;
    height: 'auto';
    .rank {
      width: 10%;
    }
    .bar-container {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background-color: #ebebeb;
      .bar {
        background-color: $blue;
        width: 100%;
        margin-top: auto;
      }
    }
    .rank-item-container-left {
      padding: 1em 0;
      border-right: 2px solid #6c757d;
    }
    .rank-item-container-right {
      padding: 1em 0;
      border-left: 2px solid #6c757d;
    }

    .text {
      width: 80px;
    }
    .bar {
      height: 40px;
    }
    .rank-first-bar {
      background-color: #28efdc;
    }
    .rank-last-bar {
      background-color: #ef5e5e;
    }
    .above-avg-bar {
      background-color: #3684EC;
    }
    .below-avg-bar {
      background-color: #ffbd42;
    }
  }

  .emos {
    display: flex;
    justify-content: space-between;
    img {
      padding: 0 1px;
      height: 48px;
    }
  }
  input[type="text"]::placeholder {
    font-size: 1em;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      display: block;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  label {
    cursor: pointer;
  }
      svg {
        height: 4em;
        width: 4em;
      }
  .error {
    text-align: center;
    font-size: 0.7em;
    color: red;
  }
}

.reviews {
  svg {
    height: 1.7em;
    width: auto;
  }
  .pages {
    a {
      cursor: pointer;
      color: #aeaeae;;
      &.selected {
        color: initial;
        font-weight: 500;
      }
    }
  }
}


ul.emoji-group[data-name="recently_used"] {
  display: none;
}

.font-weight-bold {
  font-weight: bold;
}
