$grey: #c7c7c7;
$light-grey: #b9b9b9;
$dark-grey: #6c757d;
$red: #cf0505;

:root {
  scroll-behavior: initial;
}

body {
  background-color: #f8f8f8;
}

input {
  border-radius: 20px;
  border: 1px solid $grey;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  outline: none;

  &::placeholder {
    color: $light-grey;
    opacity: 1;
    font-size: 0.8em;
  }
  @media (max-width: 350px) {
    &::placeholder {
      font-size: 0.8em;
    }
  }
  &:-ms-input-placeholder {
    color: $light-grey;
  }
  &::-ms-input-placeholder {
    color: $light-grey;
  }
  &:-webkit-autofill {
    color: red;
  }

  &:focus {
    border-color: $dark-grey;
  }
  &.brand {
    width: 100px;
    flex: 2;
  }
  &.model {
    width: 100%;
    flex: 4;
  }
}

.modal {
  .btn-primary {
    background-color: $dark-grey;
    border-color: $dark-grey;
  }
  .btn-secondary {
    background-color: white;
    color: $dark-grey;
  }
}

.rank-them-btn {
  background-color: $dark-grey;
  position: fixed;
  right: 0;
  padding: 5px 1em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
  font-size: 1.25em;
  top: 300px;
  z-index: 10;
}

@media screen and (max-width: 480px) { 
  div { 
    display: block; 
  } 
}

.container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

.bg-black {
  background-color: black;
}

@media (min-width: 576px) {
  .container {
    max-width: 768px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 768px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 768px !important;
  }
}

.sticky {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f8f8;
}
